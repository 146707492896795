<template>
    <div class="rules">
        <div class="content">
            <transition name="fade" mode="out-in">
                <div class="c">
                    <div class="c-info-block">
                        <h1 class="c-title">Помощь</h1>
                        <p class="c-info-text">
                            Здесь вы сможете получить ответы на часто задаваемые вопросы.
                        </p>
                    </div>

                    <div class="c-frame">
                        <div class="apreloader" v-if="!answers.length > 0"/>
                        <div class="c-concrete-block" v-else>
                            <div class="spoiler" v-for="answer in answers">
                                <div class="title-block" v-if="!answer.show" @click="rulesetOpeningControl(answer, 'open')" style="cursor: pointer">
                                    <p class="spoiler-title">{{ answer.answer }}</p>
                                </div>
                                <div class="title-block" v-else>
                                    <p class="spoiler-title" style="cursor: text;"
                                       :class="{'isActive':answer.show }">{{ answer.answer }}</p>

                                    <div class="close-spoiler-block" v-if="answer.show"
                                         @click="rulesetOpeningControl(answer, 'close')">X
                                    </div>
                                </div>
                                <div class="spoiler-border"
                                     v-if="answer.show"></div>
                                <div v-show="answer.show">
                                    <p v-for="info in answer.question">{{ info }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {PreloaderMixin} from "../mixins/PreloaderMixin";
import {SpoilerOpeningMixin} from "../mixins/SpoilerOpeningMixin";

export default {
    name: "Help",
    mixins: [PreloaderMixin, SpoilerOpeningMixin],

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Помощь',
    },

    data() {
        return {
            answers: []
        }
    },

    methods: {
        getHelp() {
            const axios = require('axios');
            axios.get('/api/faq').then(data => {
                data.data.forEach(i => {
                    i.show = false;
                    this.answers.push(i);
                })
                this.preloaderStop('.apreloader')
            })

        }
    },


    created() {
        this.getHelp()
    },

    mounted() {
        this.preloaderStartLk('.apreloader')
    }

}

</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.content {
    @include flexible();
    width: 100vw;
    .c {
        @include simple-ctrr ();
        @include in-page-preloader();
    }
}

@include ct-n-rp-transitions();


</style>
